import { Accordion, Box, Button, Card, Divider, HStack, Progress, Text, VStack } from '@chakra-ui/react';
import { TaskRecord } from '@karya/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { backendApi } from 'src/features/apiSlice';
import { downloadObjectAs } from 'src/helpers/DownloadData';
import { AccordionSection, Section } from 'src/helpers/Section';
import { useDataset, useTask } from 'src/hooks/Data';
import { ColorMap } from 'src/themes/Attributes';
import { ButtonVariant } from 'src/themes/ButtonVariants';

export function TaskSummary() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const taskId = queryParams.get('taskId') ?? undefined;
  const validationTaskId = queryParams.get('validationTaskId') ?? undefined;
  const testValidationTaskId = queryParams.get('testValidationTaskId') ?? undefined;
  const mainAvatarBulkId = queryParams.get('mainAvatarBulkId') ?? '';
  const validationAvatarBulkId = queryParams.get('validationAvatarBulkId') ?? '';
  const testValidationAvatarBulkId = queryParams.get('testValidationAvatarBulkId') ?? '';

  const [getAvatarsForBulkTrigger] = backendApi.useGetAllAvatarsForBulkMutation();

  const { task: mainTask, isFetchingTask: isFetchingMainTask, taskFetchError: mainTaskFetchError } = useTask(taskId);
  const {
    task: validationTask,
    isFetchingTask: isFetchingValidationTask,
    taskFetchError: validationTaskFetchError,
  } = useTask(validationTaskId);
  const {
    task: testValidationTask,
    isFetchingTask: isFetchingTestValidationTask,
    taskFetchError: testValidationTaskFetchError,
  } = useTask(testValidationTaskId);

  const { dataset: inputDataset } = useDataset(mainTask?.params?.referenceDatasetId);

  const downloadAvatarsForBulk = (bulk_id: string, task?: TaskRecord) => {
    getAvatarsForBulkTrigger(bulk_id)
      .unwrap()
      .then((avatars) => {
        const today = new Date(Date()).toLocaleDateString('en-IN', {
          dateStyle: 'medium',
        });
        const data = avatars.map((avatar) => {
          return {
            access_code: `A-${avatar.access_code}`,
            is_linked: avatar.is_linked,
            test: avatar.test,
          };
        });
        let bulkName = '';
        if (task) {
          bulkName = task.name;
        } else {
          bulkName = 'AvatarBulk';
        }
        bulkName += `_${bulk_id}_${today}`;
        downloadObjectAs(data, bulkName, 'csv');
      });
  };

  if (isFetchingMainTask || isFetchingValidationTask || isFetchingTestValidationTask) {
    return (
      <Box rounded="5px" padding="10px">
        <VStack>
          <Text>Loading Task</Text>
          <Progress isIndeterminate />
        </VStack>
      </Box>
    );
  }

  if (mainTaskFetchError) {
    return (
      <Box rounded="5px" padding="10px">
        <VStack>
          <Text textColor="red" mb={'5px'}>
            {mainTaskFetchError}
          </Text>
          <Text textColor="red" mb={'5px'}>
            {validationTaskFetchError}
          </Text>
          <Text textColor="red">{testValidationTaskFetchError}</Text>
        </VStack>
      </Box>
    );
  }

  if (!taskId) {
    return (
      <Box rounded="5px" padding="10px">
        <VStack>
          <Text textColor="red">Invalid ID</Text>
        </VStack>
      </Box>
    );
  }

  const mainTaskParams = mainTask!.params;
  const mainTaskOutputDatasetId = mainTask!.output_dataset_id;

  const validationTaskParams = validationTask?.params;
  const validationTaskOutputDatasetId = validationTask?.output_dataset_id;

  const testValidationTaskParams = testValidationTask?.params;
  const testValidationTaskOutputDatasetId = testValidationTask?.output_dataset_id;

  return (
    <Card padding="20px">
      {/* Title */}
      <VStack alignItems="start" marginBottom="20px" width="100%">
        <Text fontWeight="medium" fontSize="23px">
          Task Summary
        </Text>
        <Divider marginY="10px" />
      </VStack>
      <Accordion allowToggle>
        {mainTask && (
          <AccordionSection level="l1" heading="Main Task Details">
            <Section heading={mainTaskParams?.name} subHeading={mainTaskParams?.description} level="l2" noDivider>
              <Button
                variant={ButtonVariant.submit}
                marginTop="10px"
                marginBottom="10px"
                onClick={() => navigate(`/tasks/${taskId}`)}
              >
                Go To Task
              </Button>
              {mainTaskParams.referenceDatasetId && (
                <Section heading="Input Dataset" level="l2" noDivider>
                  <Button
                    variant={ButtonVariant.submit}
                    marginTop="10px"
                    onClick={() => navigate(`/datasets/${mainTaskParams.referenceDatasetId}`)}
                  >
                    View Input Dataset
                  </Button>
                </Section>
              )}
              <Section heading="Output Dataset" level="l2" noDivider>
                <Button
                  variant={ButtonVariant.submit}
                  marginTop="10px"
                  onClick={() => navigate(`/datasets/${mainTaskOutputDatasetId}`)}
                >
                  View Output Dataset
                </Button>
              </Section>
            </Section>
          </AccordionSection>
        )}
        {validationTask && (
          <>
            <AccordionSection heading="Validation Task Details" level="l1">
              <Section
                // @ts-ignore
                heading={validationTaskParams?.name}
                subHeading={validationTaskParams?.description}
                level="l2"
                noDivider
              >
                <Button
                  variant={ButtonVariant.submit}
                  marginTop="10px"
                  marginBottom="10px"
                  onClick={() => navigate(`/tasks/${validationTaskId}`)}
                >
                  Go To Validation Task
                </Button>
              </Section>
              <Section heading="Output Dataset" level="l2" noDivider>
                <Button
                  variant={ButtonVariant.submit}
                  marginTop="10px"
                  onClick={() => navigate(`/datasets/${validationTaskOutputDatasetId}`)}
                >
                  View dataset
                </Button>
              </Section>
            </AccordionSection>
            {testValidationTask && (
              <AccordionSection level="l1" heading="Test Validation Task Details">
                <Section
                  // @ts-ignore
                  heading={testValidationTaskParams?.name}
                  subHeading={testValidationTaskParams?.description}
                  level="l2"
                  noDivider
                >
                  <Button
                    variant={ButtonVariant.submit}
                    marginTop="10px"
                    marginBottom="10px"
                    onClick={() => navigate(`/tasks/${testValidationTaskId}`)}
                  >
                    View Details
                  </Button>

                  <Section heading="Output Dataset" level="l2" noDivider>
                    <Button
                      variant={ButtonVariant.submit}
                      marginTop="10px"
                      onClick={() => navigate(`/datasets/${testValidationTaskOutputDatasetId}`)}
                    >
                      View dataset
                    </Button>
                  </Section>
                </Section>
              </AccordionSection>
            )}
          </>
        )}
        {(mainAvatarBulkId || validationAvatarBulkId || testValidationAvatarBulkId) && (
          <AccordionSection level="l1" heading="Avatar Details">
            <Box>
              {mainAvatarBulkId && (
                <HStack mb={'7px'} justifyContent={'space-between'} fontSize={'16px'}>
                  <Text color={ColorMap.stockGray} fontWeight={'medium'}>
                    Main task avatar bulk
                  </Text>
                  <Text
                    color={ColorMap.impactGreen}
                    fontWeight={'semibold'}
                    cursor={'pointer'}
                    onClick={() => downloadAvatarsForBulk(mainAvatarBulkId, mainTask)}
                  >
                    Download
                  </Text>
                </HStack>
              )}
              {validationAvatarBulkId && (
                <HStack mb={'7px'} justifyContent={'space-between'} fontSize={'16px'}>
                  <Text color={ColorMap.stockGray} fontWeight={'medium'}>
                    Main validation task avatar bulk
                  </Text>
                  <Text
                    color={ColorMap.impactGreen}
                    fontWeight={'semibold'}
                    cursor={'pointer'}
                    onClick={() => downloadAvatarsForBulk(validationAvatarBulkId, validationTask)}
                  >
                    Download
                  </Text>
                </HStack>
              )}
              {testValidationAvatarBulkId && (
                <HStack mb={'7px'} justifyContent={'space-between'} fontSize={'16px'}>
                  <Text color={ColorMap.stockGray} fontWeight={'medium'}>
                    Test validation task avatar bulk
                  </Text>
                  <Text
                    color={ColorMap.impactGreen}
                    fontWeight={'semibold'}
                    cursor={'pointer'}
                    onClick={() => downloadAvatarsForBulk(testValidationAvatarBulkId, testValidationTask)}
                  >
                    Download
                  </Text>
                </HStack>
              )}
            </Box>
          </AccordionSection>
        )}
      </Accordion>
    </Card>
  );
}
