import Joi from 'joi';
import { ServerRole, serverRoles } from '../apis/Index';

export const ReportKinds = ['platform', 'project', 'task', 'worker', 'miscellaneous', 'graphs'] as const;

export type ReportObject = {
  name: string;
  type: 'static' | 'dynamic';
  query: string;
  params?: {
    name: string;
    type: string;
    value?: any;
    comments?: string;
  }[];
  public?: boolean;
  allowedFor?: ServerRole[];
  projectIds?: string[];
};

export type CollectionObject = {
  name: string;
  type: 'collection';
  items: {
    id: string;
    name: string;
    query: string;
    type: 'bar' | 'filtered-bar' | 'filtered-grouped-bar';
  }[];
  public?: boolean;
  allowedFor?: ServerRole[];
  projectIds?: string[];
};

export const ReportObjectSchema = Joi.object({
  name: Joi.string().required(),
  type: Joi.string().valid('static', 'dynamic').required(),
  query: Joi.string().required(),
  params: Joi.array().items(
    Joi.object({
      name: Joi.string().required(),
      type: Joi.string().required(),
      value: Joi.any(),
      comments: Joi.string(),
    })
  ),
  public: Joi.boolean(),
  allowedFor: Joi.array().items(Joi.string().valid(...serverRoles)),
  projectIds: Joi.array().items(Joi.string()),
});

export const CollectionObjectSchema = Joi.object({
  name: Joi.string().required(),
  type: Joi.string().valid('collection').required(),
  items: Joi.array().items(
    Joi.object({
      id: Joi.string().required(),
      name: Joi.string().required(),
      query: Joi.string().required(),
      type: Joi.string().valid('bar', 'filtered-bar', 'filtered-grouped-bar').required(),
    })
  ),
  public: Joi.boolean(),
  allowedFor: Joi.array().items(Joi.string().valid(...serverRoles)),
  projectIds: Joi.array().items(Joi.string()),
});

export type ReportKind = (typeof ReportKinds)[number];

export type AllReports = Record<
  ReportKind,
  {
    [id: string]: ReportObject | CollectionObject;
  }
>;

export const AllReportsSchema = Joi.object({
  platform: Joi.object().pattern(Joi.string(), ReportObjectSchema),
  project: Joi.object().pattern(Joi.string(), ReportObjectSchema),
  task: Joi.object().pattern(Joi.string(), ReportObjectSchema),
  worker: Joi.object().pattern(Joi.string(), ReportObjectSchema),
  miscellaneous: Joi.object().pattern(Joi.string(), ReportObjectSchema),
  graphs: Joi.object().pattern(Joi.string(), CollectionObjectSchema),
});
