import { DatasetShape, GenericInputParam, GenericOutputParam, NgGenericTask } from '@karya/core';
import { useMemo } from 'react';
import { MetadataField, metadataFields } from 'src/helpers/task-previews/Types';

export function useSpecShape(spec?: NgGenericTask): DatasetShape | undefined {
  // Get the shape of the main spec
  const shape = useMemo(() => {
    if (!spec) {
      return undefined;
    }
    const components = Object.values(spec);

    const dataKeys = new Set<string>();
    const fileKeys = new Set<string>();

    // Get all the data and file keys
    components.forEach((component) => {
      // Get all the output params
      const outputParamEntries = Object.entries(component).filter(
        ([key, param]) => !isMetadataField(key) && isOutputParam(param)
      );

      // For each output param, add the key to the appropriate set
      outputParamEntries.forEach(([_, param]: [string, GenericOutputParam]) => {
        if (param.type === 'FILE') {
          fileKeys.add(param.key);
        } else {
          dataKeys.add(param.key);
        }
      });
    });
    return { data: Array.from(dataKeys), files: Array.from(fileKeys) };
  }, [spec]);
  return shape;
}

function isMetadataField(fieldName: string): fieldName is MetadataField {
  return metadataFields.includes(fieldName as MetadataField);
}

function isOutputParam(param: GenericInputParam | GenericOutputParam): param is GenericOutputParam {
  return !('src' in param);
}
