import { DatapointRecord, DatasetShape, NgGenericTask, TaskParams } from '@karya/core';
import { ParameterForm } from '@karya/parameter-specs';
import { useCallback } from 'react';
import { genAI } from 'src/features/genAiSlice';
import { useForm } from 'src/helpers/parameter-renderer/hooks';
import { useDatapoints } from 'src/hooks/Data';

export type GenerateSpecForm = { prompt: string };
export type SpecKind = 'MAIN' | 'VALIDATION' | 'INTRO' | 'TUTORIAL' | 'CONFIG' | 'SURVEY';

const generateSpecForm: ParameterForm<GenerateSpecForm> = [
  {
    label: '',
    description: '',
    parameters: [
      {
        id: 'prompt',
        label: 'Generate Using AI',
        description: 'Type the prompt to generate the task specification with AI',
        type: 'string',
        long: true,
        required: false,
      },
    ],
    required: true,
  },
];

export type UseGenerateSpecProps = {
  taskParams: Partial<TaskParams>;
  kind: SpecKind;
  datasetShape?: DatasetShape;
  spec: NgGenericTask;
  setSpec: (spec: NgGenericTask) => void;
};
export function useGenerateSpec(opts: UseGenerateSpecProps) {
  const { ctx, validate } = useForm<GenerateSpecForm>({
    parameters: generateSpecForm,
  });

  const [generateSpecTrigger, { isLoading }] = genAI.useGenerateSpecMutation();

  const sampleDatapoints = useDatapoints(opts.taskParams.referenceDatasetId);

  const handleSpecGeneration = useCallback(async () => {
    const { value, error } = validate();
    if (!value || error) {
      return;
    }
    const basePrompt = value.prompt;

    const promptText = getPrompt(opts.kind, basePrompt, opts.taskParams, sampleDatapoints);

    let mainTaskSpec: NgGenericTask | undefined;
    let datasetShape: DatasetShape | undefined;

    if (['VALIDATION', 'TUTORIAL'].includes(opts.kind)) {
      mainTaskSpec = opts.taskParams.spec;
    }

    if (opts.datasetShape && !['INTRO', 'TUTORIAL', 'CONFIG'].includes(opts.kind)) {
      datasetShape = opts.datasetShape;
    }

    const response = await generateSpecTrigger({
      user_prompt: promptText,
      dataset_shape: datasetShape,
      main_spec: mainTaskSpec,
      current_spec: opts.spec,
    }).unwrap();

    if (response.spec) {
      opts.setSpec(response.spec);
    }
  }, [opts.taskParams.spec, opts.datasetShape, opts.kind, opts.setSpec]);
  return { generateSpecCtx: ctx, handleSpecGeneration, isLoading };
}

function getPrompt(
  kind: SpecKind,
  userPrompt: string,
  taskParams: Partial<TaskParams>,
  sampleDatapoints: DatapointRecord[]
) {
  let promptPreface = '';
  const {
    requiresValidation,
    baseCreditsPerMicrotask,
    bonusCreditsPerMicrotask,
    isValidationTask,
    createValidationTask,
    enableTestTask,
    maxTestAssignmentsPerUser,
    minAcceptedTestResponsesPerUser,
    maxTestReassignmentsPerUser,
    'taskAssignment.maxAssignmentsPerUser': maxAssignmentsPerUser,
  } = taskParams;

  let mainTaskMetadata = `\n`;
  if (taskParams.name) {
    mainTaskMetadata += `The task name is ${taskParams.name}\n`;
  }
  if (taskParams.description) {
    mainTaskMetadata += `The task description is ${taskParams.description}\n`;
  }
  if (taskParams.instructions) {
    mainTaskMetadata += `The task instructions are ${taskParams.instructions}\n`;
  }
  if (taskParams.display_name) {
    mainTaskMetadata += `The task display name is ${taskParams.display_name}. Display name is for the workers to see\n`;
  }
  if (taskParams.isValidationTask) {
    mainTaskMetadata += `The main task is a validation task that validates some other task. It requires the worker to accept or reject assignments from some other worker. The spec will most likely contain mostly components whose ctype is PLATFORM\n`;
  }

  switch (kind) {
    case 'MAIN':
      promptPreface = `Generate the spec is for the main task. ${mainTaskMetadata} `;
      promptPreface += `Here are some sample datapoints to take reference form: ${sampleDatapoints}`;
      break;
    case 'VALIDATION':
      promptPreface = `The validation task should be designed to validate the main task. The main task specification is already provided\n. 
For each component with ctype 'USER' in the main task ensure that there is a corresponding PLATFORM component.
Also copy relevant data from the PLATFORM components to ensure that the validator has enough infomration to validate and they know what they are validating against.\n`;

      if (!isValidationTask && requiresValidation && createValidationTask) {
        promptPreface += `Some metadata about the task we're validating is as follows: ${mainTaskMetadata}\n`;
      }
      break;
    case 'INTRO':
      promptPreface = `Please generate the intro task specification for the main task. ${mainTaskMetadata}`;

      if (baseCreditsPerMicrotask || bonusCreditsPerMicrotask) {
        const baseRate = (baseCreditsPerMicrotask ?? 0) / 100;
        const bonusRate = (bonusCreditsPerMicrotask ?? 0) / 100;
        promptPreface += `Let the user know the following information about payment:
- Base Pay Per Assignment (the amount they earn regardless of weather they pass the validation or not): INR ${baseRate}
- Bonus Pay Per Assignment (based on the quality of the work): INR ${bonusRate}\n`;
        if (maxAssignmentsPerUser) {
          const minAmount = baseRate * (maxAssignmentsPerUser ?? 0);
          const maxAmount = (baseRate + bonusRate) * (maxAssignmentsPerUser ?? 0);
          promptPreface += `Maximum number of assignments they can recieve is ${maxAssignmentsPerUser}
Minimum amount they can recieve is ${minAmount}
The worker can potentially earn a maximum of ${maxAmount} for this task.\n`;
          if (requiresValidation) {
            promptPreface += `Note that this is the maximum amount they can earn, and to reach it they must complete all the assignments and pass the validation as well\n`;
          } else {
            promptPreface += `The worker needs to complete all the microtask assignments to earn this maximum amount\n`;
          }
        }
      } else {
        promptPreface += `There is no payment associated wih this task\n`;
      }

      if (isValidationTask) {
        promptPreface += `This task is a validation task. As part of the main assignments, the worker will have to accept or reject work done by other workers for some other task\n`;
      }

      if (enableTestTask) {
        promptPreface += `This task requires the worker to pass a test to ensure the worker is capable of doing the task properly.
To pass the test the user needs to pass validation for ${minAcceptedTestResponsesPerUser} assignments from a total of ${maxTestAssignmentsPerUser} assignments.
In the test phase they will get ${maxTestReassignmentsPerUser} reassignments per assignment. 
In the reassignments they can correct thire mistakes\n`;
      }
      promptPreface += `You can only use PLATFROM TEXT components in the intro task. The user will not be able to interact with the task in any way\n`;

      break;

    case 'TUTORIAL':
      promptPreface = `Please provide the tutorial task specification for the main task. 
Let the user know that this is for helping them familiarize themselves with the task and they wont be paid. Try to convey this in a gentle and friendly manner\n
Take a single simplified example of the task from the main specification.
`;
      if (sampleDatapoints.length > 0) {
        const datapointsStr = sampleDatapoints.map((dp) => JSON.stringify(dp)).join('\n');
        promptPreface += `Use the following datapoint as an example: \n${datapointsStr}\n`;
      }
      break;

    case 'CONFIG':
      promptPreface = `Generate the CONFIG spec. This spec will have several questions as PLATFORM TEXT components, always followed by a USER component for getting input worker the worker.`;
  }

  let finalPrompt = promptPreface;

  if (userPrompt) {
    finalPrompt +=
      "\nFollow the instructions that come after this as precisely as possible to ensure that the spec generated is as per requirement. They take more priority over the other instructtions in this prompt. However make sure the specification you genreate is valid at all costs, by making sure you follow the previously described rules and don't voilate any of the types\n";
  }
  return finalPrompt + userPrompt;
}
