import { Project } from '@karya/core';
import { ParameterForm } from '@karya/parameter-specs';

export const createProjectForm: ParameterForm<Project> = [
  {
    label: 'Project Details',
    parameters: [
      {
        id: 'name',
        label: 'Project Name',
        type: 'string',
        required: true,
      },
      {
        id: 'description',
        label: 'Project Description',
        type: 'string',
        long: true,
        required: true,
      },
      {
        // @ts-ignore: Parameter definition typing does not take into account nesting
        id: 'props.tags',
        label: 'Project Tags',
        type: 'list',
        required: true,
      },
      {
        id: 'payments_enabled',
        label: 'Enable payments',
        description: 'Enable payments for tasks in this project',
        type: 'boolean',
        initial: true,
        required: false,
      },
    ],
    required: true,
  },
];
