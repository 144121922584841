import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { backendApi } from 'src/features/apiSlice';
import { genAI } from 'src/features/genAiSlice';
import { projectReducer } from 'src/features/projectsSlice';
import { reportsApi } from 'src/features/reportsApi';

// Redux store
export const store = configureStore({
  reducer: {
    [backendApi.reducerPath]: backendApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [genAI.reducerPath]: genAI.reducer,
    currentProject: projectReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(backendApi.middleware, reportsApi.middleware, genAI.middleware),
});

setupListeners(store.dispatch);

// Types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Typed functions
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
